export class HeroSlider {
  $elm: JQuery;

  constructor(elm: string) {
    this.$elm = $(elm);
    this._init();
  }

  _init() {
    this.$elm.bxSlider({
      auto: true,
      mode: 'fade',
      speed: 1500,
      pause: 6000,
      pager: false,
      controls: false
    });
  }
}
