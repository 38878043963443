import { HeroSlider } from './_modules/_HeroSlider';

(function ($) {

  $(function() {
    const top = new Top();
  });

  class Top {
    $html: JQuery;
    $body: JQuery;

    constructor() {
      this.$html = $('html');
      this.$body = $('body');
      this._init();
    }

    _init() {
      const hero = new HeroSlider('.bxslider');
    }
  }

})(jQuery);
